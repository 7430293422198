import React from 'react'
import { Form } from "react-bootstrap"
function myplaceholder(props) {
  return <div>{props.html}</div>;
}

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props)} />;
}
const RadioboxField = ({fieldClass, name, value, required, placeholder, handlechange, checked,step}) => (
      <div className="form-check form-check-inline">
      <input type="radio" id={name+value} name={name} checked={checked} value={value} onChange={handlechange} className="form-check-input" required={required} />
      <label title="" for={name+value} class="form-check-label">{placeholder}</label>
      </div>
);

export default RadioboxField;