/**
 * External dependencies
 */
import React from "react";

/**
 * Internal dependencies
 */
import "./styles/_index.scss"

const NavUtils = ({ children }) => {
  return <nav className="nav-utils">{children}</nav>
}

export default NavUtils;
