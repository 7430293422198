/**
 * External dependencies
 */
import React, {useState} from "react";
import Reviews from "../reviews/reviews";
import Button from "../button/button";
import parse from 'html-react-parser';
import GETCTA from '../getcta';
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { AltSiteName } from "../utils";
import Breadcrumbs from "../breadcrumbs";
import CustomVideo from "../PlayVideo/html-popup";
import { Helmet } from "react-helmet";
/**
 * Internal dependencies
 */
import "./styles/_index.scss";
import BannerVideo from "../../images/Lamborn-hill-Home-page-video.mp4"

const HomeIntro = (props) => {
  const [show, setShow] = useState(false);

  let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms;
  }

  const divClassName = props.pagename === "Home"
    ? "home-intro intro-with-video"
    : props.bannerimage
    ? "home-intro intro-with-banner"
    : "home-intro"

  return (
    
    <div
      id={props.layout === "Static" ? "static-page-layout" : ""}
      className={
        divClassName
      }
      data-aos="fade-up"
    >
       {props.pagename === "Home" &&
      <Helmet>
          <script type="application/ld+json">{`{
"@context":"http://schema.org",
"@type": "LocalBusiness",
"name": "LambornHill",
"image": "https://www.lambornhill.com/static/logo-7830a5ce7a19b1450b31def831f04e5f.svg",
"telephone": ["01795 293 000"],
"areaServed": "Sittingbourne, Isle of Sheppey, Kent",
"url": "https://www.lambornhill.com/",
"description": "LambornHill are the locally trusted estate agents &amp; letting agents in Sittingbourne, Isle of Sheppey and Kent.",
 "address" : {
    "@type" : "PostalAddress",
    "streetAddress" : "19-21 West Street",   
    "addressLocality" : "Sittingbourne",  
    "postalCode" : "Kent ME10 1AJ"
  },
 "openingHoursSpecification" : {
    "@type" : "OpeningHoursSpecification",
    "dayOfWeek" : {
      "@type" : "DayOfWeek",
      "name" : "Mo, Tu, We, Th, Fr 09:00-18:00 Sa 09:00-17:00"
    }
  },
"sameAs": [
  "https://www.facebook.com/lambornhill.estateagents/",
 "https://www.instagram.com/lambornhill/", "https://www.youtube.com/channel/UCy_iuUTodH-bgm-35knwTRQ",
"https://twitter.com/lamborn_hill"
]
}`}</script>
</Helmet>}
      {props.pagename === "Home" && (
        <>
        <div className="banner-intro">
          <video
            width="100%"
            height="100%"
            autoPlay
            muted={true}
            playsInline
            loop
          >
            <source src={props.bannerVideo} type="video/mp4" />
          </video>
        {/* <div className="play-icon">
        <a href="javascript:void(0)" onClick={() => { setShow(true); }}>
          <i className="icon-play-btn"></i>
        </a>
        </div> */}
        <CustomVideo url={props?.bannerVideo} vtype={`vimeo`} htmlvideo='show' />
        </div>
        <div className="video-overlay"></div>
        </>
      )}
     
      {props.bannerimage ? (
        <div className="banner-intro">
          <div className="banner-img-sec">
          <div className="bannerOverlay"></div>
          <ImageTransform
            imagesources={props.bannerimage && props.bannerimage.url}
            renderer="srcSet"
            imagename={
              props.imagename
                ? props.imagename
                : "articles.Banner_Image.bannerimg"
            }
            attr={{
              alt: props.bannerimage.alternativeText
                ? props.bannerimage.alternativeText
                : props.title + AltSiteName,
            }}
            imagetransformresult={processedImages}
            id={props.id}
          />
        </div>
        </div>
      ) : (
        ""
      )}
      <div className="shell">
        {props.pagename != "Home" && (
          <Breadcrumbs
            baselabel={props.baselabel ? props.baselabel : ""}
            baseurl={props.baseurl ? props.baseurl : ""}
            menulabel={props.menulabel ? props.menulabel : ""}
          />
        )}
        <h1>
          {/* You local Estate Agent in <span>Sittingbourne, Isle of Sheppey</span>{" "}
          and surrounding areas. */}
          {parse(props.title)}
        </h1>
        {props.content && (
          <div className="banner-desc">{parse(props.content)}</div>
        )}
        {props.ctas && (
          <div className="home-intro__actions">
            {/* <Button className="btn btn--outline-white">Book a Valuation</Button>
            <Button className="btn btn--outline-white">Find a Property</Button> */}
            {props.ctas &&
              props.ctas.map((item, key) => {
                return (
                  <>
                    <GETCTA
                      link={
                        item.Banner_CTA_Link && item.Banner_CTA_Link.Primary_URL
                      }
                      customlink={item.Banner_CTA_Secondary_Link}
                      class="btn btn--outline-white"
                      Label={item.Banner_CTA_Label}
                    />
                  </>
                )
              })}
          </div>
        )}
        {props.reviews && <Reviews className="reviews--row" />}
      </div>
    </div>
  )
}

export default HomeIntro;
