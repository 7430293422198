/**
 * External dependencies
 */
 import React from "react";
 import { StaticImage } from "gatsby-plugin-image";
 import { Row, Col } from "react-bootstrap";
 
 /**
  * Internal dependencies
  */
 import Button from "../button/button";
 import "./styles/_index.scss";
 import PropertyPerson from '../property-person/property-person';
 import DefaultTimePicker from "../time-picker/time-picker";
 import DatePicker from "../date-picker/date-picker";
 import SendFriend from "../forms/sendfrnd-form"
 const BookViewing = (props) => {
   return (
     <div className="contact-valuation book-view" data-aos="fade-up">

       <div className="shell">
         <div className="contact-valuation__inner">
           <div className="contact-valuation__content">
             <div className="contact-valuation__head">
               <h2>Send to friend</h2>
               <p>
               Please submit the following form and share this property with your friend.
               </p>
             </div>
 
             <div className="valuation">
                <SendFriend property_url={props.property_url} property_id={props.id} page_url={props.property_url} property_title={props.title ? props.title : ''} property_img={props.images ? props.images.url : ''} property_address={props.address_details ? props.address_details : ''} property_bedrooms={props.bedrooms ? props.bedrooms : ''} property_bathrooms={props.bathrooms ? props.bathrooms : ''}  />               
             </div>
          </div>
           <div className="contact-valuation__image">
         <StaticImage
           src="./images/bg.jpg"
           alt="House"
           style={{ height: "100%", width: "100%" }}
         />
       </div>
         </div>
       </div>
     </div>
   )
 }
 
 export default BookViewing;
 