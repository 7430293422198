/**
 * External dependencies
 */
import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"

/**
 * Internal dependencies
 */
import "./styles/_index.scss"
import Button from "../../components/button/button"
import { isMobile } from "../../lib/media"
import ContactPageForm from "../../components/forms/contact"
const schema = yup.object().shape({
  fullName: yup.string().required("Please enter your full name"),
  email: yup
    .string()
    .email("Invalid email. Example: someexample@mail.com")
    .required("Please enter you email"),
  phone: yup.string().required("Please enter you phone number"),
  message: yup.string().min(5, "Message must be at least 5 symbols."),
})

const ContactForm = (props) => {
  //console.log('aaaaaaa', props)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onFormSubmit = data => data

  return (
    <div className="form contact-form">
      {props.teamid && props.teamdata && props.teamdata.teams.length > 0 ?
        <ContactPageForm to_email_id={props.teamdata.teams[0].Email} formtype="Staff Contact" subject="Staff"/>
      :
        <ContactPageForm subject={props.subject} formtype={props.formtype} messagelabel={props.messagelabel} messageplaceholder={props.messageplaceholder} />
      }
      {/* <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form__body">
          <div className="form__row">
            <label htmlFor="fullName" className="form__label">
              Your Name
            </label>
            <input
              {...register("fullName")}
              type="text"
              id="fullName"
              name="fullName"
              className="form__field"
              placeholder="Enter your full name here"
            />
          </div>
          {errors.fullName && (
            <p className="error">{errors.fullName.message}</p>
          )}

          <div className="form__row">
            <label htmlFor="email" className="form__label">
              Your Email Address
            </label>
            <input
              {...register("email")}
              type="text"
              id="email"
              name="email"
              className="form__field"
              placeholder="Enter your email here"
            />
          </div>
          {errors.email && <p className="error">{errors.email.message}</p>}

          <div className="form__row">
            <label htmlFor="phone" className="form__label">
              Your Telephone
            </label>
            <input
              {...register("phone")}
              type="text"
              id="phone"
              name="phone"
              className="form__field"
              placeholder="Enter your telephone number here"
            />
          </div>
          {errors.phone && <p className="error">{errors.phone.message}</p>}

          <div className="form__row">
            <label htmlFor="message" className="form__label">
              Message
            </label>
            <textarea
              {...register("message")}
              type="text"
              id="message"
              name="message"
              className="form__field form__field-textarea"
              placeholder="Enter your message here"
            />
          </div>
          {errors.message && <p className="error">{errors.message.message}</p>}
        </div>

        <div className="form__footer">
          <div className="form__actions">
            <Button className="btn--fill-lightblue">Submit Enquiry</Button>
          </div>
          <p>
            By Clicking submit you agree to our 
            {
              isMobile 
                ? <Link to="#"> T&amp;C</Link>
                : <Link to="#"> Terms &amp; Conditions</Link>
            }
          </p>
        </div>
      </form> */}
    </div>
  )
}

export default ContactForm
