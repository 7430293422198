/**
 * External dependencies
 */
import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import parse from 'html-react-parser';

/**
 * Internal dependencies
 */
import Stars from "../stars/stars";
import Reviews from "../reviews/reviews";
import "./styles/_index.scss";
import axios from "axios"
import Carousel, { consts } from "react-elastic-carousel"
import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import EstatsReviews from "../estats-reviews"
const HomeFeedback = (props) => {
  const [testimonials, setTestimonials] = useState([])
  const formatter = buildFormatter(frenchStrings)
  const strapiconfig = {
      headers: {
          Authorization:
              `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      },
  }
  const getitems = async url => {
    try {
        const { data } = await axios.get(url,strapiconfig)// could be from env files
        setTestimonials(data)
    } catch (error) {
        // cache it if fail/error;
        console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url);
  }, [])




  return (
    <div className={props.title == 'Home' ? 'home-feedback only-home-feedback':"home-feedback inner-feedback"}>
      <div className="shell">
        <Row className="home-feedback__row">
          <Col className="home-feedback__content">
            <div className="home-feedback__text">

              <Carousel enableAutoPlay autoPlaySpeed={3000}>

              {testimonials &&
               testimonials.length > 0 &&
               testimonials.map((item, index) => {
                if(item.starRating =="FIVE" && item.comment && item.comment.length > 0){

              return <>
              <Stars count={item?.starRating}/>
              {/* <h2>
                “Friendly people. Very competitive fees. Market leading customer
                service.”
              </h2> */}
              {item.comment &&
                <p>{parse(item.comment.substring(0,200))}{parse(item.comment.length>200?'...':'')}</p>
              }
              <h4>{item.reviewer.displayName}</h4>
              {/* <time>{item.createTime}</time> */}
              <time><TimeAgo date={item.createTime} formatter={formatter} /></time>
               </>
                }
               })}
              </Carousel>

            </div>

            <div className="home-feedback__reviews">
              <Reviews className="reviews--blue" />
            </div>
          </Col>

          <Col className="home-feedback__image">
            <div className="home-feedback__image-inner">
              <StaticImage
                src="./images/feedback-bg.jpg"
                alt="Feedback Kid Image"
                style={{width: "100%", height: "100%"}}
              />
            </div>
          </Col>
        </Row>
      </div>
      {props.title == 'Home' &&
        <EstatsReviews/>
      }
    </div>

  )
}

export default HomeFeedback;
