/**
 * External dependencies
 */
import React from "react";
import { Link } from "gatsby";
import parse from 'html-react-parser';
/**
 * Internal dependencies
 */
import "./styles/_index.scss";
import ShortIntro from '../property-details/short-intro'
const HomeText = (props) => {
  return (
    <>
    {props.sidebar === true?
      <ShortIntro content={props.content} />
    :
    <div className="home-text" data-aos="fade-up">
      <div className="shell">
        <div className="home-text__inner">
          <div className="home-text__entry">
            {parse(props.content)}           
          </div>
          {props.cta &&
          <div className="home-text__actions">
            <Link to={`/${props.cta.Primary_URL}/`} className="cta-link cta-link--revert">
              <span>{props.label}</span>
              <i className="icon-arrow-right"></i>
            </Link>
          </div>
          }
        </div>
      </div>
    </div>
    }
    </>
  )
}

export default HomeText;
