import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import ImageLoad from "./imageload"
import { AltSiteName, NewsBaseURL } from "./utils"

const GetThumbnailCTA = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allMenus(sort: "Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Main_Parent {
            id
            Label
            Primary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
          }
        }
      }
    }
  `)
  var menu_data = data.glstrapi.allMenus
  console.log("customlink", props.ctas)

  const Thumbnail = (
    <ImageLoad
      src={props.item.Tile_Image.url}
      height="100%"
      alt={
        props.item.Tile_Image.alternativeText
          ? props.item.Tile_Image.alternativeText
          : props.item.Tile_Label + AltSiteName
      }
    />
  )

  return (
    <>
      {menu_data.map(menuitem => {
        return (
          <>
            

            {menuitem.Main_Parent === null &&
              menuitem.Sub_Parent === null &&
              props.link === menuitem.Primary_URL &&
              !props.customlink && (
                <Link to={`/${menuitem.Primary_URL}/`} className={props.class}>
                  {Thumbnail}
                </Link>
              )}

            {menuitem.Main_Parent != null &&
              menuitem.Sub_Parent === null &&
              props.link === menuitem.Primary_URL &&
              !props.customlink && (
                <Link
                  to={`/${menuitem.Main_Parent.Primary_URL}/${menuitem.Primary_URL}/`}
                  className={props.class}
                >
                 {Thumbnail}
                </Link>
              )}
            {menuitem.Main_Parent != null &&
              menuitem.Sub_Parent != null &&
              props.link === menuitem.Primary_URL &&
              !props.customlink && (
                <Link
                  to={`/${menuitem.Main_Parent.Primary_URL}/${menuitem.Sub_Parent.Primary_URL}/${menuitem.Primary_URL}/`}
                  className={props.class}
                >
                 {Thumbnail}
                </Link>
              )}
          </>
        )
      })}
      {props.customlink && (
        <Link to={`${props.customlink}/`} className={props.class}>
          {Thumbnail}
        </Link>
      )}
    </>
  )
}
export default GetThumbnailCTA
