/**
 * External dependencies
 */
 import React from "react"
 import { Col, Form, Row } from "react-bootstrap"
 
 /**
  * Internal dependencies
  */
 import Button from "../button/button"
 import { useStaticQuery, graphql } from "gatsby"
 import NewsletterPageForm from "../../components/forms/newsletter-form"

 import "./styles/_index.scss"
 const NewsletterForm = () => {

 
   return (
     <div className="formnewsletter newsletter-landing property-find" data-aos="fade-up" data-item="map" id="map">
       <div className="shell">
         <div className="property-find__inner">
           <NewsletterPageForm />
           {/* <Form>
             <Row>
               <Col md="7">
                 <Form.Group
                   controlId="property-location"
                   className="find-field"
                 >
                   <Form.Control
                     type="text"
                     placeholder="Email Address"
                   />
                 </Form.Group>
               </Col>
               <Col xs="6" md="auto">
                 <Button className="btn--outline-white">
                 Subscribe 
                 </Button>
               </Col>
             </Row>
           </Form> */}
         </div>
       </div>
     </div>
   )
 }
 
 export default NewsletterForm
 