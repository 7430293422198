import React from 'react'
import parse from 'html-react-parser';

import { Form } from "react-bootstrap"

function myplaceholder(props) {
  return <div>{props.html}</div>;
}

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props)} />;
}

const CheckboxField = ({fieldClass, name, value, required, placeholder, label, handlechange}) => (
  <div className="mb-3 data">
      <div class="form-check form-check-inline">
        <MyComponent html={placeholder} />
            <input required={required} className="form-check-input position-static" type="checkbox" id={name} name={name} value={value} />
            <span class="form-check-label">{parse(label)}</span>
      </div>
  </div>
);

export default CheckboxField