import React, { useState } from "react"
import { Helmet } from "react-helmet"

const SocialWall = () => {
    return (
        <div className="social-wall common-top-section">
           <div className="shell">
              <div
      className="elfsight-app-e01c3089-48b0-465a-aedc-473c12618069"
      
    >
      <Helmet>
        <script
          type="text/javascript"
          src="//apps.elfsight.com/p/platform.js"
          async={true}
        />
      </Helmet>
     
    </div>

            </div>
        </div>
    )
}
 
export default SocialWall