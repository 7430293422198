/**
 * External dependencies
 */
 import React, { useState, useEffect, useRef } from "react";
 import Helmet from "react-helmet"


 const EstatsReviews = () => {

    const [renderComponent, setRenderComponent] = useState(false);

    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => { 
            if (renderComponent === false) {
              setRenderComponent(true)
            }
          })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        }) 
    }, [])
    if (typeof window !== "undefined") {
    (function (w,d,s,o,f,estas) {
    w['ESTAS-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
    let js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }
    (window, document, 'script', 'estas', 'https://www.theestas.com/assets/js/min/widget.bundle.js'));
    }


     return (
        <>
        


{renderComponent &&
        <>        
        <Helmet 
        script={[{ 
            type: 'text/javascript', 
            innerHTML: "(function (w,d,s,o,f,js,fjs) {w['ESTAS-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);}(window, document, 'script', 'estas', 'https://www.theestas.com/assets/js/min/widget.bundle.js'));estas('init', { el: 'estas-widget', key: 'MlT0J44L1BzgHYD3YanRx37EQVCGaZghr8Jm4Y8SeUL64oUzzS7GyAGSzp7H', showComments: true });"
        }]} />
        </>
        }


        <section className="estas-feebacks">
            <div className="shell">
                <div id="estas-widget"></div>
            </div>
        </section>
        </>
     )
 }
 
 export default EstatsReviews;
 