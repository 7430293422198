/**
 * External dependencies
 */
 import React, { useState } from "react"
 import { Link } from "gatsby"
 import Carousel, { consts } from "react-elastic-carousel"
 import { StaticImage } from "gatsby-plugin-image"
 import { useStaticQuery, graphql } from "gatsby"
 
 /**
  * Internal dependencies
  */
 import News from "../news/news"
 import "./styles/_index.scss"
 import classNames from "classnames"
 import VideoPlaceholder from "../video-placeholder/video-placeholder"
 import { AllNews } from "../../queries/common_use_query";
 import DateFormat from "../format-date";
 import { AltSiteName, NewsBaseURL } from "../utils"
 import ImageTransform from "../common/ggfx-client/module/components/image-transform";
 import { Col, Form, Row } from "react-bootstrap"
 import parse from 'html-react-parser';
 import { Helmet } from "react-helmet";
 
 const NewsListing = () => {
   const {loading:loading, error:error, data:data} = AllNews(0, 1);
   const {loading:all_loading, error:all_error, data:all_data} = AllNews(1, -1);

   const [isOpen, setIsOpen] = useState(false)
   const breakPoints = [
     { width: 1, itemsToShow: 1 },
     { width: 550, itemsToShow: 2 },
     { width: 800, itemsToShow: 3 },
     { width: 1128, itemsToShow: 4 },
   ]
 
   const myArrow = ({ type, onClick, isEdge }) => {
     const pointer =
       type === consts.PREV ? (
         <i className="icon-arrow-left"></i>
       ) : (
         <i className="icon-arrow-right"></i>
       )
     return (
       <span
         onClick={onClick}
         disabled={isEdge}
         className={classNames("slide-arrow", `slide-arrow--${type}`)}
       >
         {pointer}
       </span>
     )
   }
   function removeTags(str) {
    if ((str===null) || (str===''))
    return false;
    else
    str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
 }

   return (
     <div className="home-news news-listing-page common-top-section">
      <Helmet>
  <script type="application/ld+json">{`{
  "@context":"http://schema.org",
    "@type":"ItemList",
    "itemListElement":[
              
      {
        "@type":"ListItem",
        "position":"1", 
        "url": "https://www.lambornhill.com/about/property-blogs/why-buying-a-property-in-the-uk-is-so-appealing-to-international-investors/"
      },
      {
        "@type":"ListItem",
        "position":"2", 
        "url": "https://www.lambornhill.com/about/property-blogs/uk-house-prices-peak-is-it-the-right-time-to-sell-and-buy-property/"
      },
      {
        "@type":"ListItem",
        "position":"3", 
        "url": "https://www.lambornhill.com/about/property-blogs/mortgage-rejections-at-a-high-how-to-boost-your-chances-of-acceptance-local-sittingbourne-property-management-experts-explain/"
      },
      {
        "@type":"ListItem",
        "position":"4", 
        "url": "https://www.lambornhill.com/about/property-blogs/new-uk-property-register-for-overseas-owners-tells-our-local-sittingbourne-property-management-team/"
      },
      {
        "@type":"ListItem",
        "position":"5", 
        "url": "https://www.lambornhill.com/about/property-blogs/prepare-your-home-for-a-romantic-valentines-day-top-tips-from-sittingbourne-s-local-property-management-experts/"
      },
      {
        "@type":"ListItem",
        "position":"6", 
        "url": "https://www.lambornhill.com/about/property-blogs/free-landlord-and-tenant-advice-from-local-property-experts/"
      },
      {
        "@type":"ListItem",
        "position":"7", 
        "url": "https://www.lambornhill.com/about/property-blogs/tenants-advice-in-sittingbourne-winter-housekeeping-tips-for-renters/"
      },
      {
        "@type":"ListItem",
        "position":"8", 
        "url": "https://www.lambornhill.com/about/property-blogs/essential-tips-on-pet-friendly-rentals/"
      },
      {
        "@type":"ListItem",
        "position":"9", 
        "url": "https://www.lambornhill.com/about/property-blogs/the-best-buy-to-let-areas-and-advice-on-buy-to-let-property-investment-from-the-experts-1/"
      },
      {
        "@type":"ListItem",
        "position":"10", 
        "url": "https://www.lambornhill.com/about/property-blogs/average-house-prices-in-the-uk-are-down-for-the-first-time-in-a-year/"
      },
            {
        "@type":"ListItem",
        "position":"11", 
        "url": "https://www.lambornhill.com/about/property-blogs/interior-design-ideas-home-garden-trends-and-creating-the-ideal-home-in-2021/"
      },
      {
        "@type":"ListItem",
        "position":"12", 
        "url": "https://www.lambornhill.com/about/property-blogs/mortgage-rates-dropped-as-the-uk-mortgage-market-returns-to-pre-pandemic-activity/"
      },
      {
        "@type":"ListItem",
        "position":"13", 
        "url": "https://www.lambornhill.com/about/property-blogs/with-rent-rising-private-landlords-have-never-had-a-better-time-to-buy-and-rent-a-property/"
      }
   ]

 }`}</script>
 </Helmet>
       <div className="shell">

        <div className="featured-news-section">
        {data && data.newsBlogs.length > 0 && data.newsBlogs.map((item, index) => {
            if(index ===0) {
            let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = item.imagetransforms.Tile_Image_Transforms;
            }
            return<>
            <div className="overflow-image-wrapper tab-content">
                <div className="tab-pane">
                    <div className="tab-wrapper">
                    <div className="tab-img img-zoom featured-news-tile">
                     <Link to={`${NewsBaseURL}/${item.URL}/`}>
                     <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.featuretile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  
                     </Link>
                     </div>
                     <div className="tab-info">
                     <span className="news__heading"><Link to={`${NewsBaseURL}/${item.URL}/`}>{item.Title}</Link></span>
                     <DateFormat date={item.News_Published_Date}/>
                     <p>{parse(removeTags(item.Above_Content.substr(0, 230)+'...'))}</p>
                     <div className="continue-feat">
                     <Link to={`${NewsBaseURL}/${item.URL}/`}><span>Continue Reading</span><i className="icon-arrow-right"></i></Link>
                     </div>
                     </div>
                     </div>
                     </div>
            </div>
            </>
            }
        })}
        </div>
         <div className="home-news__carousel">
         <Row>
           {all_data && all_data.newsBlogs.length > 0 && all_data.newsBlogs.map((item, index) => {
             let processedImages = JSON.stringify({});
             if (item?.imagetransforms?.Tile_Image_Transforms) {
             processedImages = item.imagetransforms.Tile_Image_Transforms;
             }
             return<>
             <Col lg="3" md="6">
             <div className="news">
               <div className="news__head">
                 <div className="news__head-inner">
                   {item.Tile_Video_URL?
                     
                     <VideoPlaceholder
                     isOpen={isOpen}
                     setIsOpen={setIsOpen}
                     imagetype="GGFX"
                     ggfxname="news-blogs.Tile_Image.slidertile"
                     imageprocess={processedImages}
                     linkto={`${NewsBaseURL}/${item.URL}/`}
                     videourl={item.Tile_Video_URL}
                     imageid={item.id}
                     altid = {item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}
                     image={
                       item.Tile_Image && item.Tile_Image.url
                     } />      
                     
                     :
                     <div className="overflow-image-wrapper">
                     <Link to={`${NewsBaseURL}/${item.URL}/`}>
                     <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.slidertile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  
                     </Link>
                     </div>
                     // <img src={item.Tile_Image.url} />           
                   }
                 </div>
               </div>
               <div className="news__body">
                 <DateFormat date={item.News_Published_Date}/>
                 <span className="news__heading"><Link to={`${NewsBaseURL}/${item.URL}`}>{item.Title}</Link></span>
               </div>
             </div>
             </Col>
             </>
           })}
           </Row>
         </div>
       </div>
     </div>
   )
 }
 
 export default NewsListing
 