/**
 * External dependencies
 */
 import React from "react"
 import { StaticImage } from "gatsby-plugin-image"
 import { Col, Row } from "react-bootstrap"
 import parse from 'html-react-parser';

 /**
  * Internal dependencies
  */
 import ItemList from "../item-list/item-list"
 import IconCircle from "../icon-circle/icon-circle"
 
 import "./styles/_index.scss"
 import ImageLoad from '../imageload';
 import { AltSiteName } from "../utils"
 
 const PropertyCols = (props) => {
   return (
   <div className="property-cols image-blocks">
     <div className="shell">
       {/* <div className="property-cols__head">
         <h4>{props.title}</h4>        
         <p>
         {props.description}
         </p>
       </div> */}
       <div className="property-cols__inner">
         <Row>
           <Col lg="6" className="order-md-1 order-lg-0">
            {parse(props.description)}
           {/* {props.iconlist && props.iconlist.length > 0 && props.iconlist.map((item, index) => {
             return<>
             <ItemList>
               <ItemList.Icon>
                 <IconCircle className="w-80" color="#EAF2F4">
                   <i className={`icon-${item.Icon_Class}`}></i>
                 </IconCircle>
               </ItemList.Icon>
               <ItemList.Content>
                 <h5>{item.Icon_Title}</h5>
                 <p>
                   {item.Icon_Description}
                 </p>
               </ItemList.Content>
             </ItemList>
             </>
             })}             */}
           </Col>
           {props.image &&
           <Col lg="6" className="image-col">
             <div className="image-wrapper">
               <ImageLoad src={props.image.url} height="100%" alt={props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName} />              
             </div>
           </Col>
            }
         </Row>
       </div>
     </div>
   </div>
   )
 }
 
 export default PropertyCols
 