import React from 'react'
import Button from "../../../components/button/button"
import Col from "react-bootstrap/Col"
import { isMobile } from "../../../lib/media"
import { Link } from "gatsby"

const ButtonField = ({type, name, value, fieldClass, step,handlechange,handlechange2}) => (
	<div className={`btns ${step}`}>
		<div className="form__footer">
          <div className="form__actions">
		  <Button variant="" type={type} className={fieldClass}>
			{name}
		  </Button>
          </div>
          <p>
            By Clicking submit you agree to our 
            {
              isMobile 
                ? <Link to="/terms-and-conditions/"> T&amp;C</Link>
                : <Link to="/terms-and-conditions/"> Terms &amp; Conditions</Link>
            }
          </p>
        </div>
	</div>
);

export default ButtonField;