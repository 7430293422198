import React, { useState } from "react";
import { Col, Row , Container} from 'react-bootstrap';
import CountUp from 'react-countup';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import { StaticImage } from "gatsby-plugin-image";
const COUNT_UP_START = 0;
const COUNT_UP_DURATION = 3;

const Stats = (props) => {
    
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <div className="home-stats">
                <Container>
                <Row>
                <div className="stats-head">
                    <h2>{props.heading}</h2>
                    {/* <p>{props.caption}</p> */}
                </div>
                {typeof window !== 'undefined' && props.stats.map((item, key) => {
                    const count = item.Stats_Value;
                    return <>
                    <Col xs="6" md="6" lg="3" className="stat-wrap">
                        <h2 ref={ref}>
                            <CountUp
                                start={inView ? COUNT_UP_START : count }
                                end={item.Stats_Value}
                                delay={0}
                                duration={COUNT_UP_DURATION}
                            />{item.Stats_Prefix}
                        </h2>
                        <div className="stats-caption">{item.Stats_Caption}</div>
                    </Col>
                    </>
                    })}  
                    {typeof window !== 'undefined' &&
                    <Col xs="6" md="6" lg="3" className="stat-wrap feefo-img">
           
                        <i className="icon-feefo"></i>
                        <div className="stats-caption">Customer Service Rating</div>
                    </Col>    
                    }
                </Row>
                </Container>
        </div>
    )
}

export default Stats

