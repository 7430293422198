import React, {useEffect} from 'react';
import { MapMarkerInfoBox } from './map-marker';
import LocRatingMap from './loc-rating-map-landing';
import { AreaguidesURL } from '../utils';
import { useLocation } from "@reach/router"
import ReactDOMServer from "react-dom/server"
import parse from "html-react-parser"


const LandingMap = (props) => {   
    const location = useLocation();
    var rootpath = location.origin;
    // Get Map Details 
    let mapItems = [];
    {props.locations &&
        props.locations.map((location, key) => {
        if(location.Latitude && location.Longitude) {
            let mapItem = {};
            mapItem['lat']  = location.Latitude;        
            mapItem['lng']  = location.Longitude;
            const mapMarkerImageShowFlag = 1;
            var link = "<a class='mapviewbtn' target='_parent' style='float:left;width:100%;color: #fff;font-weight:bold;margin-top:10px;background: #1B1434;padding: 8px;border-radius: 4px;text-align: center;' href="+rootpath+AreaguidesURL+"/"+location.URL+">View Areaguide</a>";
            var areaTitle = "<span style='font-weight:bold;display: inline-block;margin-top: 5px;'>"+location.Name+" Areaguide</span><br>";
            let mapItemHtml = ReactDOMServer.renderToStaticMarkup(
                <div className="marker" /*style={_styles}*/>
                  <div className="info-box" style={{ "text-align": "center" }}>
                    <img src={location.Tile_Image.url} alt={""} height="160px" width="200px" style={{ "object-fit": "cover" }}/>
                    <div style={{ color: "#1B1434", width: "200px", height: "80px" }}>
                        {parse(areaTitle + link)}
                    </div>
                  </div>
                </div>
              )
            mapItem['html'] = mapItemHtml;
            mapItems.push( mapItem );
        }
    })};    
    return (
        <div className="property-location detail-map" data-aos="fade-up">
            <div>
            <LocRatingMap data={mapItems}/>
            </div>
        </div>
    )
}

export default LandingMap
