import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Select from "react-dropdown-select";


const SelectField = ({name, required, placeholder, values, handlechange, fieldClass, label, grpmd, componentprops}) => {
 
  var selectvalues = []
  {values.map(val =>selectvalues.push({ value:val, label: val } ))}

  return (
  
  <Form.Group  md={grpmd} controlId={`validation${name}`}>
    { label
      ? <Form.Label>{label}{required ? '*' : ''}</Form.Label>
      : ''

    }
    
    <Select placeholder={placeholder}  name={name} onChange={handlechange} required={required} className="departments form-control"  searchable={false} options={selectvalues} values={[]} />    
    {/* <Form.Control
		className={fieldClass}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
    value={componentprops ? componentprops[name] : ''}
		>
		{values.map(val =><option value={val} key="">{val}</option>)}
    </Form.Control> */}
  </Form.Group>
  )
}

export default SelectField;