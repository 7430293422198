/**
 * External dependencies
 */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { PropertValuationURL } from "../utils"

/**
 * Internal dependencies
 */
import NavUtils from "../nav-utils/nav-utils";
import ContactForm from "../../blocks/contact-form/contact-form";
import "./styles/_index.scss"

const ContactInfo = (props) => {
  const getOffset = el => {
    var _x = 0
    var _y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft
      _y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return { top: _y, left: _x }
  }

  const scrollHandle = (e) => {
    e.preventDefault();
    const element = document.querySelector(`#contact-map`);
    const offestTop = getOffset(element).top;
    const stickyHeight = document.querySelector('.page-contact').clientHeight;

    window.scrollTo({
      top: stickyHeight - offestTop - 1500,
      behavior: 'smooth'
    });
  }

  return (
    <div className="contact-info" data-aos="fade-up">
      <div className="shell">     
        <div className="contact-info__inner">
          <Row className="contact-info__row">
            <Col className="contact-info__content">
              <ContactForm
                teamid={props.teamid}
                teamdata={props.teamdata}
                formtype={props.formtype}
                messagelabel={props.messagelabel}
                messageplaceholder={props.messageplaceholder}
                subject={props.subject}
              />
            </Col>

            <Col className="contact-info__sidebar">
              <h3>Contact Details</h3>

              <NavUtils>
                <ul>
                  <li>
                    <div className="nav-utils__icon-wrapper">
                      <i className="icon-location"></i>
                    </div>

                    <div>
                      <p>
                        LambornHill, 19-21 West Street, Sittingbourne, Kent,
                        ME10 1AJ
                      </p>
                      {!props.formtype && (
                        <a onClick={scrollHandle}>View map</a>
                      )}
                      
                    </div>
                  </li>

                  <li>
                    <div className="nav-utils__icon-wrapper">
                      <i className="icon-phone-bold"></i>
                    </div>

                    <div>
                      <a href="tel:+01795293000">01795 293000</a>
                    </div>
                  </li>

                  <li>
                    <div className="nav-utils__icon-wrapper">
                      <i className="icon-envelope"></i>
                    </div>

                    <div>
                      <p>
                        sittingbourne@lambornhill.com
                      </p>
                    </div>
                  </li>

                  <li>
                    <div className="nav-utils__icon-wrapper">
                      <i className="icon-price"></i>
                    </div>

                    <div>
                      <p>Do you need to sell your home?</p>
                      <Link to={PropertValuationURL}>Book a valuation </Link>
                      <span>with one of our team</span>.
                    </div>
                  </li>
                </ul>
              </NavUtils>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo;
