import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap"
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
// import {Link}  from "gatsby"
import ReactPlayer from 'react-player'
// import { useLocation } from "@reach/router"
import $ from "jquery"
import {
    isIOS,
    isMobile
} from "react-device-detect";
import "./custom-video.scss"


const CustomVideo = (props) => {
    //const [isOpen, setOpen] = useState(false);
    //console.log('showHTMLVideo',props.vtype, props.url)
    const url = typeof window !== 'undefined' ? window.location.href : '';

    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': url,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }

    //   const [isPlay, setPlay] = useState(false);
    const vidRef = useRef(null);
    const [show, setShow] = useState(false);
    const [play, setPlayvideo] = useState(true);
    const [mute, setMute] = useState(true);
    const [controls, setVideoControls] = useState(false);


    const [showHTMLVideo, setHTMLVideo] = React.useState(false);
    const pausevideo = (ref) => {
        setPlayvideo(false)
        setVideoControls(false)

    }
    const playvideo = (ref) => {
        setPlayvideo(true)
        setVideoControls(false)
        setTimeout(function () { $(".modal-bg-black").hide() }, 1700);
    }
    const showControls = () => {
        setVideoControls(true)
    }
    useEffect(() => {
        $("#video1").bind("ended", function() {
            //alert('test');
            setHTMLVideo(false);
         });
        });
    return (
        <>
            <div class="play-icon">
                <a href="javascript:void(0)" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); trackerVideo(); }}>
                    <i class="icon-play-btn"></i>
                </a>
            </div>
            {showHTMLVideo &&

                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-video"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdropClassName="video-backdrop"
                    className="full-video-popup"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {isMobile &&
                            <div className="modal-bg-black">
                            </div>
                        }
                        <video className="htmlpopup--video" id="video1" width="400" controls={true} autoPlay={true} muted={false} playsInline >
                        <source src={props.url} type="video/mp4"/>
                        </video>
                    </Modal.Body>
                </Modal>
            }

        </>
    )
}

export default CustomVideo