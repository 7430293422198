/**
 * External dependencies
 */
 import React from "react";
 import HTMLReactParser from 'html-react-parser';
 import Reviews from "../reviews/reviews";

 /**
  * Internal dependencies
  */
 import "./styles/_index.scss";
 import NewsletterForm from "../property-find/newsletter-form";
 import Breadcrumbs from "../breadcrumbs";

 const CenterIntro = (props) => {
   return (
     <div className="contact-intro" data-aos="fade-up">
       <div className="shell">
         <Breadcrumbs />
         <div className="contact-intro__content">
            {props.teamid && props.teamdata && props.teamdata.teams.length > 0 ?
            <h1>Contact {props.teamdata.teams[0].Name}</h1>
            :            
            <>
            <h1>{props.title}</h1>             
            {HTMLReactParser(props.content)}
            </>
            }
            {props.pagename === "News" &&
              <NewsletterForm />
            }   
            {props.reviews &&
              <Reviews className="reviews--row banner-center-reviews" />
            }       
         </div>
       </div>
       
     </div>
   )
 }
 
 export default CenterIntro;
 