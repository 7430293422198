/**
 * External dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Row, Col } from "react-bootstrap";

/**
 * Internal dependencies
 */
import Button from "../button/button";
import "./styles/_index.scss";
import PropertyPerson from '../property-person/property-person';
import DefaultTimePicker from "../time-picker/time-picker";
import DatePicker from "../date-picker/date-picker";
import { Helmet } from "react-helmet"
import LoadExternalScript from "../utils/load-external-script";
import axios from "axios"
import $ from "jquery"

const ContactValuation = () => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef()
    const LeadproScriptsJs = 'https://assets.lead.pro/js/widgets/proval/v1/page.js';


    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Valuation',
                'formType': 'Instant Valuation',
                'formId': 'Instant Valuation',
                'formName': 'Instant Valuation',
                'formLabel': 'Instant Valuation'
            });
        }
        if (document.getElementById("bugherd") === null) {  
            var bugherdScript = document.createElement('script');
            bugherdScript.id = 'bugherd';
            bugherdScript.async = true;
            bugherdScript.type = 'text/javascript';
            bugherdScript.src = 'https://assets.lead.pro/js/widgets/proval/v1/page.js';
            document.getElementsByTagName('body')[0].appendChild(bugherdScript);
            setLoaded(true);
        }
        if(!loaded) {
            (function (w, d, n) {
                w[n] = w[n] || function () {
                    (w[n].q = w[n].q || []).push(arguments)
                }
            })(window, document, 'LeadPro')
            if(window.LeadPro && typeof window.LeadPro == "function") {
                window.LeadPro('init', {
                    appId: 'f8559ade-32f1-426e-8b2f-454666bee80b',
                    pageId: '809',
                    container: 'lead-pro-widget'
                });
            }
            if(window.LeadPro && typeof window.LeadPro == "object") {
                window.LeadPro.init({
                    appId: 'f8559ade-32f1-426e-8b2f-454666bee80b',
                    pageId: '809',
                    container: 'lead-pro-widget'
                });
            }
        }
    }, [])
      
    return (
<>
<h1 className="d-none">Instant Valuation</h1>
<div className="overlay-valuation"></div> 
        <div className="contact-valuation" data-aos="fade-up">        
            
            <div id='lead-pro-widget'></div>
                        
        </div>
        </>
    )
}

export default ContactValuation;
