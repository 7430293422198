/**
 * External dependencies
 */
 import React from "react"
 import { Col, Form, Row } from "react-bootstrap"
 import { Link } from "gatsby"
 
 /**
  * Internal dependencies
  */
 import Button from "../button/button"
 import { useStaticQuery, graphql } from "gatsby"
 
 import "./styles/_index.scss"
 import { openPopupWidget } from "react-calendly";
 const PropertyFind = () => {
  const onClickCalendly = (url) => openPopupWidget({ url });
   const data = useStaticQuery(graphql`
     query ValQuery {
       glstrapi {
           globalConfig {
            Property_Video_Valuation {
               Section_Title
               Section_Description
             }
           }
         }
       }     
   `)
   var fetch_data = data.glstrapi.globalConfig.Property_Video_Valuation;
   const url = typeof window !== 'undefined' ? window.location.href : ''; 
   const GTMTracker = (event) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          'event': 'Book your video Valuation',
          'formType': url,
          'formId': 'Book your video',
          'formName': 'Book your video',
          'formLabel': 'Book your video'
      });
    }
}
   return (
     <div
       className="property-find val-prop"
       data-aos="fade-up"
       data-item="map"
       id="map"
     >
       <div className="shell">
         <div className="property-find__inner">
           <h2>{fetch_data.Section_Title}</h2>
           <p>{fetch_data.Section_Description}</p>
           <Link to="/property-valuation/" className="btn btn--outline-white">
              <div>Book your valuation</div>
              <i className="icon-arrow-right"></i>
           </Link>

           {/* <button
             onClick={() =>
               onClickCalendly("https://calendly.com/lambornhill/30min")
             }
             className="btn btn--outline-white"
           >
             <div>Book your valuationnn</div>
             <i className="icon-arrow-right"></i>
           </button> */}
         </div>
       </div>
     </div>
   )
 }
 
 export default PropertyFind
 