/**
 * External dependencies
 */
 import React, { useState } from "react"
 import { Link } from "gatsby"
 import Carousel, { consts } from "react-elastic-carousel"
 import { StaticImage } from "gatsby-plugin-image"
 import { useStaticQuery, graphql } from "gatsby"
 import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
 
 /**
  * Internal dependencies
  */
 import News from "../news/news"
 import "./styles/_index.scss"
 import classNames from "classnames"
 import VideoPlaceholder from "../video-placeholder/video-placeholder"
 import { CategoryNews } from "../../queries/common_use_query";
 import DateFormat from "../format-date";
 import { AltSiteName, NewsBaseURL } from "../utils"
 import ImageTransform from "../common/ggfx-client/module/components/image-transform";
 
 
 const CategoryBlogSlide = (props) => {
   const {loading:loading, error:error, data:data} = CategoryNews(props.category.URL);
   const [isOpen, setIsOpen] = useState(false)
   const breakPoints = [
     { width: 1, itemsToShow: 1 },
     { width: 550, itemsToShow: 2 },
     { width: 800, itemsToShow: 3 },
     { width: 1128, itemsToShow: 4 },
   ]

   let itemsShownNo = 0
   if(isMobileOnly){
    itemsShownNo = 1
   } else if (isTablet) {
    itemsShownNo = 2
   } else if (isDesktop) {
    itemsShownNo = 4
   }

   const padItemsNo = data && data.newsBlogs.length < itemsShownNo ? itemsShownNo - data.newsBlogs.length : 0
   
   const myArrow = ({ type, onClick, isEdge }) => {
     const pointer =
       type === consts.PREV ? (
         <i className="icon-arrow-left"></i>
       ) : (
         <i className="icon-arrow-right"></i>
       )
     return (
       <span
         onClick={onClick}
         disabled={isEdge}
         className={classNames("slide-arrow", `slide-arrow--${type}`)}
       >
         {pointer}
       </span>
     )
   }
 
   return <>
    {data && data.newsBlogs.length > 0 &&
     <div className="home-news sub-tiles">
       <div className="shell">
         <div className="home-news__head">
           <h2>{props.title}</h2>
           <Link to="/about/property-blogs/">View More</Link>
         </div>

         <div className="home-news__carousel">
           <Carousel breakPoints={breakPoints} renderArrow={myArrow}>
             {data &&
               data.newsBlogs.length > 0 &&
               data.newsBlogs.map((item, index) => {
                 let processedImages = JSON.stringify({})
                 if (item?.imagetransforms?.Tile_Image_Transforms) {
                   processedImages = item.imagetransforms.Tile_Image_Transforms
                 }
                 return (
                   <>
                     <div className="news">
                       <div className="news__head">
                         <div className="news__head-inner">
                           {
                             item.Tile_Video_URL ? (
                               <VideoPlaceholder
                                 isOpen={isOpen}
                                 setIsOpen={setIsOpen}
                                 imagetype="GGFX"
                                 ggfxname="news-blogs.Tile_Image.slidertile"
                                 imageprocess={processedImages}
                                 linkto={`${NewsBaseURL}/${item.URL}/`}
                                 videourl={item.Tile_Video_URL}
                                 imageid={item.id}
                                 altid={
                                   item.Tile_Image.alternativeText
                                     ? item.Tile_Image.alternativeText
                                     : item.Title + AltSiteName
                                 }
                                 image={item.Tile_Image && item.Tile_Image.url}
                               />
                             ) : (
                               <div className="overflow-image-wrapper">
                                 <Link to={`${NewsBaseURL}/${item.URL}/`}>
                                   <ImageTransform
                                     imagesources={item.Tile_Image.url}
                                     renderer="srcSet"
                                     imagename="news-blogs.Tile_Image.slidertile"
                                     attr={{
                                       alt: item.Tile_Image.alternativeText
                                         ? item.Tile_Image.alternativeText
                                         : item.Title + AltSiteName,
                                     }}
                                     imagetransformresult={processedImages}
                                     id={item.id}
                                   />
                                 </Link>
                               </div>
                             )
                             // <img src={item.Tile_Image.url} />
                           }
                         </div>
                       </div>
                       <div className="news__body">
                         <DateFormat date={item.News_Published_Date} />
                         <span className="news__heading">
                           <Link to={`${NewsBaseURL}/${item.URL}/`}>
                             {item.Title}
                           </Link>
                         </span>
                       </div>
                     </div>
                   </>
                 )
               })}

             {/* padding elements */}
             {data &&
               data.newsBlogs.length > 0 &&
               data.newsBlogs.slice(0, padItemsNo).map((item, index) => {
                 return (
                   <>
                     <div className="news  no-cursor-pointer">
                       <div className="news__head">
                         <div className="news__head-inner">
                             <div className="overflow-image-wrapper">
                               <Link to={``}>
                                 <ImageTransform />
                               </Link>
                             </div>
                         </div>
                       </div>
                       <div className="news__body">
                         <span className="news__heading">
                           <Link to={""}>{""}</Link>
                         </span>
                       </div>
                     </div>
                   </>
                 )
               })}
               {/* padding elements */}

           </Carousel>
         </div>
       </div>
     </div>
  }
   </>
 }
 
 export default CategoryBlogSlide
 