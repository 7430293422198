/**
 * External dependencies
 */
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import { Col, Row } from "react-bootstrap"

/**
 * Internal dependencies
 */

import "./styles/_index.scss"
import { useStaticQuery, graphql } from "gatsby"
import GETCTA from "../getcta"

const HomeHelp = (props) => {
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      globalConfig {
        What_Do_You_Need {
          Section_Title
          Section_Description
          Section_Links {
            Link_Label
            Link_Icon
            Select_Link {
              Primary_URL
              Main_Parent {
                Primary_URL
              }
            }
          }
        }
      }
    }
  }
`)
  var fetch_data = data.glstrapi.globalConfig.What_Do_You_Need;
  return (
    <div className={props.pageTitle === "Home" ? "home-help home-help__home-page" : "home-help"} data-aos={props.pageTitle !== "Home" && "fade-up"}>
      <div className="home-help__image">
        <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
          <StaticImage
            src="./images/bg-help.png"
            alt="Small bay with boats outside"
            style={{ width: "100%", height: "100%" }}
          />
        </Parallax>
      </div>
      <div className="shell">
        <div className="home-help__inner">
          <div className="home-help__content">
            <h2>{fetch_data.Section_Title}</h2>
            <p>
              {fetch_data.Section_Description}
            </p>
            <Row className="home-help__options gx-5">
            {fetch_data.Section_Links.map((item,key) => {
              return<>
              <Col md="6">
              <GETCTA
                      link={
                        item.Select_Link && item.Select_Link.Primary_URL
                      }
                      class="btn btn--outline-shadow"
                      Label={item.Link_Label}
                      iconclass ={`icon-${item.Link_Icon}`}
                    />

                {/* <Link to={item.Select_Link && item.Select_Link.Main_Parent?+'/'+item.Select_Link.Main_Parent.Primary_URL+'/'+item.Select_Link.Primary_URL:item.Select_Link && item.Select_Link.Primary_URL} className="btn btn--outline-shadow">
                  <i className={`icon-${item.Link_Icon}`}></i>
                  <span>{item.Link_Label}</span>
                  <i className="icon-arrow-right" />
                </Link> */}
              </Col>
              </>
            })}
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHelp
