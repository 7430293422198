/**
 * External dependencies
 */
 import React, { useState } from "react"
 import { Col, Row } from "react-bootstrap"
 
 /**
  * Internal dependencies
  */
 import VideoItem from "../video-item/video-item"
 import usePropertyVideos from "./use-property-videos"
 import { AllTeams } from "../../queries/common_use_query";
 import ImageTransform from "../common/ggfx-client/module/components/image-transform";
 import {AltSiteName, TeamURL} from '../utils'
 import { Link } from "gatsby"

 import "./styles/_index.scss"
 
 const PropertyVideos = () => {
   const {loading:loading, error:error, data:data} = AllTeams();
   const [isOpen, setIsOpen] = useState(false)
   const items = usePropertyVideos()
 
   const videoItems = items.reduce((acc, val) => {
     acc[val.node.name] = val.node.childrenImageSharp[0].fluid.src
     return acc
   }, {})
 
   return (
     <div className="property-videos-wrapper team-listing-wrap">
       <div className="property-videos" data-aos="fade-up">
         <div className="shell">
           <div className="property-videos__inner">
             <Row className="gx-5">
             {data && data.teams.length > 0 && data.teams.map((item, index) => {
                 let processedImages = JSON.stringify({});
                 if (item?.imagetransforms?.Staff_Image_Transforms) {
                 processedImages = item.imagetransforms.Tile_Image_Transforms;
                 }
                return<>
               <Col xs="12" md="6" lg="3">
                 <VideoItem
                   image={videoItems.img1}
                   isOpen={isOpen}
                   setIsOpen={setIsOpen}
                   imagetype="GGFX"
                    ggfxname="teams.Staff_Image.listtile"
                    imageprocess={processedImages}
                    propertyDetailsLink={`${TeamURL}/${item.URL}/`}
                    videourl={item.Video_URL}
                    imageid={item.id}
                    altid = {item.Staff_Image.alternativeText?item.Staff_Image.alternativeText:item.Name+AltSiteName}
                    image={
                        item.Staff_Image && item.Staff_Image.url
                    } 
                 >
                   <h4><Link to={`${TeamURL}/${item.URL}/`}>{item.Name}</Link></h4>
                   <p>{item.Designation}</p>
                   <p><a href={`tel:${item.Phone}`}>{item.Phone}</a> / <Link to={`/contact?tid=${item.id}`}>Email</Link></p>
                 </VideoItem>
               </Col>
               </>
             })}
             </Row>
           </div>
         </div>
       </div>
     </div>
   )
 }
 
 export default PropertyVideos
 