import * as React from "react";

import Layout from "../components/layout"
import BookViewing from "../components/contact-valuation/book-viewing";
import SendFriend from "../components/contact-valuation/send-to-friend";

import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { GetPropertyBooking } from "../queries/common_use_query"
import InstantValuation from "../components/contact-valuation/instant-valuation";



const ContactPage  = (props) => {
    const GQLPage = props.GQLPage;
    const GQLModules = props.GQLModules;
    const location = useLocation();    
    const searchParams = parse(location.search) // => {init: "true"}
    const teamid = searchParams.tid
    const pid = searchParams.pid
    const {loading:prop_loading, error:prop_error, data:prop_data} = GetPropertyBooking(pid);
    var propertyurl= ""
    var propertyslug = ""
    var searchtype = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].search_type:"";
    var displayaddress = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].display_address:"";
    if(prop_data && prop_data.properties.length > 0) {
        propertyurl =   searchtype=="sales"?"/property-for-sale/":"/property-to-rent/"
        propertyslug =  prop_data.properties[0].slug+'-'+prop_data.properties[0].id;
    }
    return (
        <div className="form-template-main">
            <Layout bannerimage={pid?"transparent-header":""} pageclass={GQLPage.Page_Class} >
            {GQLModules.map((Modules, i) => {
                return<>
                    {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "Booking_Form" && prop_data && prop_data.properties.length > 0 &&
                    <BookViewing property_url={location.origin+propertyurl+propertyslug} address_details={displayaddress} id={prop_data.properties[0].id} title={displayaddress} price={prop_data.properties[0].price} type={prop_data.properties[0].title} telephone= "" address_details={displayaddress} images={prop_data.properties[0].images && prop_data.properties[0].images[0]} bedrooms={prop_data.properties[0].bedroom} bathrooms={prop_data.properties[0].bathroom}/>
                    }
                    {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "Send_To_Friend" && prop_data && prop_data.properties.length > 0 &&
                    <SendFriend property_url={location.origin+propertyurl+propertyslug} address_details={displayaddress} id={prop_data.properties[0].id} title={displayaddress} price={prop_data.properties[0].price} type={prop_data.properties[0].title} telephone= "" address_details={displayaddress} images={prop_data.properties[0].images && prop_data.properties[0].images[0]} bedrooms={prop_data.properties[0].bedroom} bathrooms={prop_data.properties[0].bathroom}/>
                    }
                    
                    {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "Instant_Valuation" &&
                        <InstantValuation />
                    }
                </>
            })}
            </Layout>
        </div>
    )
}
export default ContactPage;
