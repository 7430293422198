/**
 * External dependencies
 */
import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import Carousel, { consts } from "react-elastic-carousel"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Form, Row } from "react-bootstrap"

/**
 * Internal dependencies
 */
import News from "../news/news"
import "./styles/_index.scss"
import classNames from "classnames"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import { RecentNews } from "../../queries/common_use_query"
import DateFormat from "../format-date"
import { AltSiteName, NewsBaseURL } from "../utils"
import ImageTransform from "../common/ggfx-client/module/components/image-transform"
import ImageLoad from "../imageload"
import GetCTA from "../getcta"
import { isMobileOnly } from "react-device-detect"
import GetThumbnailCTA from "../getThumbnailCTA"

const HomeNews = props => {
  const { loading: loading, error: error, data: data } = RecentNews()
  const [isOpen, setIsOpen] = useState(false)
  const carousel = useRef()
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 800, itemsToShow: 3 },
    { width: 1128, itemsToShow: 4 },
  ]

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i className="icon-arrow-left"></i>
      ) : (
        <i className="icon-arrow-right"></i>
      )
    return (
      <span
        onClick={onClick}
        disabled={isEdge}
        className={classNames("slide-arrow", `slide-arrow--${type}`)}
      >
        {pointer}
      </span>
    )
  }

  return (
    <div className="home-news sub-tiles">
      <div className="shell">
        <div className="home-news__head">
          <h2>{props.title}</h2>
          <div className="property-related mb-0">
            <div className="property-related__arrows">
              <button onClick={() => carousel.current.slidePrev()}>
                <i className="icon-arrow-left" />
              </button>
              <button onClick={() => carousel.current.slideNext()}>
                <i className="icon-arrow-right" />
              </button>
            </div>
          </div>
        </div>

        <div className="home-news__carousel">
          {isMobileOnly ? (
            <Row>
              {props.tiles &&
                props.tiles.length > 0 &&
                props.tiles.map((item, index) => {
                  return (
                    <>
                      <Col lg={3}>
                        <div className="news">
                          <div className="news__head">
                            <div className="news__head-inner">
                              <div className="overflow-image-wrapper">
                                <ImageLoad
                                  src={item.Tile_Image.url}
                                  height="100%"
                                  alt={
                                    item.Tile_Image.alternativeText
                                      ? item.Tile_Image.alternativeText
                                      : item.Tile_Label + AltSiteName
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="news__body">
                            <span className="news__heading">
                              <GetCTA
                                customlink={item.Tile_Secondary_Link}
                                Label={item.Tile_Label}
                                link={
                                  item.Tile_Link && item.Tile_Link.Primary_URL
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                    </>
                  )
                })}
            </Row>
          ) : (
            <Carousel
              ref={carousel}
              breakPoints={breakPoints}
              renderArrow={myArrow}
              showArrows={false}
            >
              {props.tiles &&
                props.tiles.length > 0 &&
                props.tiles.map((item, index) => {
                  return (
                    <>
                      <div className="news">
                        <div className="news__head">
                          <div className="news__head-inner">
                            <div className="overflow-image-wrapper">
                              <GetThumbnailCTA
                                item={item}
                                customlink={item.Tile_Secondary_Link}
                                Label={item.Tile_Label}
                                link={
                                  item.Tile_Link && item.Tile_Link.Primary_URL
                                }
                              />
                              {/* <ImageLoad
                                src={item.Tile_Image.url}
                                height="100%"
                                alt={
                                  item.Tile_Image.alternativeText
                                    ? item.Tile_Image.alternativeText
                                    : item.Tile_Label + AltSiteName
                                }
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className="news__body">
                          <span className="news__heading">
                            <GetCTA
                              customlink={item.Tile_Secondary_Link}
                              Label={item.Tile_Label}
                              link={
                                item.Tile_Link && item.Tile_Link.Primary_URL
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </>
                  )
                })}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  )
}

export default HomeNews
