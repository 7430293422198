import React from 'react';
import { graphql } from 'gatsby'
import CommonTemplate from './common-template'
import FormTemplate from './form-template'
import SEO from "../components/Seo/seo"
const DefaulTemplate = (props) => {
  const GQLPage = props.data.glstrapi?.article;
  const GQLAreaGuides = props.data.glstrapi?.areaGuides;
  const GQLModules = props.data.glstrapi?.article?.Modules;
  if (GQLPage.Layout === 'Form') {
    return (
      <>
        <SEO title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title} description={GQLPage.Meta_Description} />
        <FormTemplate GQLPage={GQLPage} GQLModules={GQLModules} />
      </>
    )
  }
  else {
    return (
      <>
        <SEO title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title} description={GQLPage.Meta_Description} />
        <CommonTemplate GQLPage={GQLPage} GQLModules={GQLModules} />
      </>
    )
  }
};
export default DefaulTemplate;

export const pageQuery = graphql`
query DefaultQuery ($articleId: ID!) {
  glstrapi {   
        areaGuides {
          Name
          Tile_Intro
          Video_URL
          id
          imagetransforms
          Latitude
          Longitude
          Tile_Image {
            url
            alternativeText
          }
        }

        article(id: $articleId, publicationState: LIVE) {
            id
            Title    
            Meta_Title
            Meta_Description  
            Page_Class
            Layout
            Banner_Title
            Banner_Reviews
            Banner_Alignment
            Banner_Description
            Select_Popular_Search
            Book_an_appointment_Video_Upload {
              url
            }
            Book_an_appointment_Video_Image {
              url
              alternativeText
            }
            Banner_Image {
              alternativeText
              url
            }
            # Banner_Video {
            #  url
            #  alternativeText
            # }
            imagetransforms
            Banner_CTA {
              Banner_CTA_Label
              Banner_CTA_Secondary_Link
              Banner_CTA_Link {
                Primary_URL
              }
            }
            Modules {
                ... on GLSTRAPI_ComponentComponentsModules {
                  id
                  Select_Module
                  __typename
                }
                ... on GLSTRAPI_ComponentComponentsForms {
                  id
                  Select_Form
                  __typename
                }
                ... on GLSTRAPI_ComponentComponentsStatisticsModule {
                  id
                  __typename
                  Stats_Module_Heading
                  Stats_Module_Caption
                  Stats_List {
                    Stats_Caption
                    Stats_Prefix
                    Stats_Value
                    id
                  }
                }
                ... on GLSTRAPI_ComponentComponentsTileBlocks {
                  id
                  __typename
                  Title
                  Description
                  Tile_Video_URL
                  Tile_CTA_Label
                  Tile_Image {
                    url
                    alternativeText
                  }
                  Tile_CTA_Link {
                    Primary_URL
                  }
                  Icon_List {
                    Icon_Title
                    Icon_Description
                    Icon_Class
                    Icon_Content
                  }
                }
                ... on GLSTRAPI_ComponentComponentsCategorizedBlogs {
                  id
                  __typename
                  Module_Title
                  Select_Category {
                    Name
                    URL
                    id
                  }
                }
                ... on GLSTRAPI_ComponentComponentsStaticPage {
                  id
                  __typename
                  Static_Content
                }
                ... on GLSTRAPI_ComponentComponentsReviewVideoTile {
                  id
                  __typename
                  Review_Video_Tile {
                    __typename
                    Video_URL
                    Reviewer_Comment
                    Reviewer_Name
                    id
                    Video_Tile_Image {
                      alternativeText
                      url
                    }
                  }
                }
                ... on GLSTRAPI_ComponentComponentsSubImageTiles {
                  id
                  __typename
                  Block_Title
                  Sub_Thumbnails {
                    Tile_Label
                    Tile_Secondary_Link
                    Tile_Link {
                      Primary_URL
                    }
                    Tile_Image {
                      url
                      alternativeText
                    }
                  }
                }
                ... on GLSTRAPI_ComponentComponentsImageBlock {
                  id
                  __typename
                  Block_Content
                  Video_URL
                  Image_Tile {
                    url
                    alternativeText
                  }
                }
                ... on GLSTRAPI_ComponentComponentsAccordionTileBlock {
                  id
                  __typename
                  Tile_Block_Title
                  Tile_Block_Image {
                    url
                    alternativeText
                  }
                  Tile_Block_Description
                  Accordion_List {
                    id
                    Accordion_Title
                    Accordion_Description
                  }
                }
                ... on GLSTRAPI_ComponentComponentsShortIntro {
                  id
                  __typename
                  Short_Intro_Content
                  Enable_Sidebar_Contact
                  Intro_CTA_Label
                  Intro_CTA_Link {
                    Primary_URL
                  }
                }
            }
        }
    }
}`
