/**
 * External dependencies
 */
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { isMobileOnly } from "react-device-detect"

/**
 * Internal dependencies
 */
import VideoItem from "../video-item/video-item"
import usePropertyVideos from "../property-videos/use-property-videos"
import grungeDark from "../../images/grunge_dark.png"
import grungeWhite from "../../images/grunge_white.png"
import LandingMap from "../map/areaguide-landing-map"
import { AllAreaguides } from "../../queries/common_use_query"
import {AreaguidesURL} from '../utils'
import {Link}  from "gatsby"

import "./styles/_index.scss"

const AreaGuideListing = props => {
  const [isOpen, setIsOpen] = useState(false)
  // const items = usePropertyVideos()
  const { loading: loading, error: error, data: data } = AllAreaguides()

  if(loading)
  return <></>

  const items = data.areaGuides

  //    const videoItems = itemsss.reduce((acc, val) => {
  //      acc[val.node.name] = val.node.childrenImageSharp[0].fluid.src
  //      return acc
  //    }, {})

  return (
    <div
      className="property-videos-wrapper area-listing-wrapper"
      data-aos="fade-up"
    >
      <div className="shell area-listing-container">
        <Row className="gx-5">
          {items &&
            items.map((item, i) => {
              //console.log('aaaaaaaa', item.Video_URL)
              if(item.Tile_Image) {
              return (
                <>
                  <Col xs="12" md="6">
                    <img
                      key={item.Name + i}
                      className="area-listing-grunge"
                      src={isMobileOnly && i === 1 ? grungeWhite : grungeDark}
                    />

                    <VideoItem
                      image={item.Tile_Image.url}
                      videourl={item.Video_URL}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      propertyDetailsLink={`${AreaguidesURL}/${item.URL}/`}
                    >
                      <div className="area-guide-content">
                        <h4>{item.Name + " Area Guide"}</h4>
                        <p>{item.Tile_Intro}</p>
                        <div className="view-area-guide">
                          <Link to={`${AreaguidesURL}/${item.URL}/`}>
                            View Area Guide
                            <i className="icon-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </VideoItem>
                  </Col>
                </>
              )
              }
            })}
        </Row>
      </div>
      {items && items[0].Latitude && items[0].Longitude && (
        <LandingMap
          locations={items}
        />
      )}
    </div>
  )
}

export default AreaGuideListing
