/**
 * External dependencies
 */
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

/**
 * Internal dependencies
 */
import Button from "../button/button";
import Reviews from "../reviews/reviews";
import "./styles/_index.scss";
import parse from 'html-react-parser';
import GetCTA from "../getcta";
import { Link } from "gatsby"
import { openPopupWidget } from "react-calendly";

const ContactBook = (props) => {
  const onClickCalendly = (url) => openPopupWidget({ url });
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const GTMTracker = (event) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          'event': 'Valuation',
          'formType': event,
          'formId': 'Expert Valuation',
          'formName': 'Expert Valuation',
          'formLabel': 'Expert Valuation'
      });
    }
}
  return (
    <div className="contact-book valuation--intro" data-aos="fade-up">
      <div className="contact-book__inner">
        <div className="contact-book__content">
          <div className="contact-book__text">
            <h1>{props.title}</h1>
            {parse(props.content)}
          </div>

          <div className="contact-book__actions">
            <Link to="/property-valuation/instant-valuation/" class="btn btn--outline-darkblue">Instant Valuation<i className="icon-arrow-right"></i></Link>
            {/* <Button className="btn--outline-darkblue">Expert Valuation</Button> */}
            <button onClick={() => {GTMTracker("Expert Valuation");onClickCalendly("https://calendly.com/lambornhill/30min")}}  className="btn btn--outline-darkblue"><div>Expert Valuation</div><i className="icon-arrow-right"></i></button>

          </div>
          {/* {props.ctas &&
          <div className="contact-book__actions">
            
                {props.ctas && props.ctas.map((item,key) => {
                  return <>
                  <GetCTA link={item.Banner_CTA_Link && item.Banner_CTA_Link.Primary_URL} customlink={item.Banner_CTA_Secondary_Link} class="btn btn--outline-darkblue" Label={item.Banner_CTA_Label} />
                  </>
                })}
          </div>
        } */}
          <Reviews className="reviews--blue" />
        </div>
        <div className="contact-book__image">
          <div className="contact-book__image-inner">
            <StaticImage
              src="./images/bg-2.png"
              alt="House"
              style={{ width: "100%", maxHeight: "890px", height: "100%"}}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default ContactBook;
